/*

Order Contact Info
Will be used to display the contact information of the user who placed the order.
This component will be used in the Checkout Page.
It will display the following information:
Email
Phone Number

*/

import React, {
  useState, useEffect, useCallback, useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  HeadingBanner, InputField, ErrorMessage, FieldGroup, Checkbox, LegalText, Link,
} from 'anf-core-react';
import isValidEmail from '../../../tools/isValidEmail';
import isValidPhone from '../../../tools/isValidPhone';
import SwitchTestContext from '../../../context/SwitchTestContext';
import { setHashedEmailForAnalytics } from '../../../tools/utils';

export default function OrderContactInfo({
  orderContactData,
  userData,
  hasOnlyEGiftCard,
  isSmsOptInEnabled,
}) {
  const [emailValue, setEmailValue] = useState(orderContactData.email ?? '');
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [smsOptIn, setSmsOptIn] = useState(false);
  const [phoneValue, setPhoneValue] = useState(orderContactData.phoneNumber ?? '');
  const [phoneInvalid, setPhoneInvalid] = useState(false);
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const isSMSOptInEnabled = digitalData && digitalData['ful-sms-opt-in'];
  const searchParams = new URLSearchParams(window.location.search);
  const showSmsOptIn = isSMSOptInEnabled || searchParams.get('enablesms') === 'true';

  const validateEmail = useCallback(() => {
    const isValid = isValidEmail(emailValue);
    setEmailInvalid(!isValid);
    return isValid;
  }, [emailValue]);

  const validatePhone = useCallback(() => {
    const isValid = isValidPhone(phoneValue);
    setPhoneInvalid(!isValid);
    return isValid;
  }, [phoneValue]);

  // this useEffect is used to set the email value
  // to the guest customer email id from session storage
  // that is initially set by recognize customer module in CRS app
  useEffect(() => {
    function setEmailValueFromSessionStorage() {
      const guestCustomerMailId = sessionStorage?.getItem('guestCustomerMailId');
      if (guestCustomerMailId && !userData.isLoggedIn) {
        setEmailValue(guestCustomerMailId);
      }
    }
    // run initially
    setEmailValueFromSessionStorage();

    // also run when event is triggered
    // This event is triggered when the CRS flow persists the user's email
    window.addEventListener('recognizeCustomer:persist:email', setEmailValueFromSessionStorage);
    // This event is triggered when the MFE recognize customer flow continues as a guest
    window.addEventListener('RecognizeCustomer:ContinueAsGuest', setEmailValueFromSessionStorage);

    return () => {
      // Remove event listeners for CRS customer recognition flow (persisting email)
      window.removeEventListener('recognizeCustomer:persist:email', setEmailValueFromSessionStorage);
      // Remove event listener for MFE customer recognition flow (continuing as guest)
      window.removeEventListener('RecognizeCustomer:ContinueAsGuest', setEmailValueFromSessionStorage);
    };
  }, [userData.isLoggedIn]);

  // New effect to hash email only when `emailValue` updates and is valid
  useEffect(() => {
    async function updateHashedEmailForAnalytics() {
      if (!emailInvalid && emailValue) {
        await setHashedEmailForAnalytics(emailValue);
      }
    }

    updateHashedEmailForAnalytics();
  }, [emailValue, emailInvalid]);

  // this useEffect is used to validate the email and phone number fields
  // it is triggered by CRS app validation that also runs through these fields
  useEffect(() => {
    function handleCartValidation() {
      validateEmail();
      validatePhone();
    }
    window.addEventListener('cartModel:validationFailed', handleCartValidation);

    return () => {
      window.removeEventListener('cartModel:validationFailed', handleCartValidation);
    };
  }, [validateEmail, validatePhone]);

  // will not render if hideContactInfo is true and it will be true only for paypalexpress flow
  // the condition is used to checked to display ordercontactinfo in other flows
  // TODO: make this check in BFF whenever url is available
  if (orderContactData?.hideContactInfo && window.location.href.includes('ProcessPayPalReturnAndOrderExpress')) {
    return null;
  }
  return (
    <div className="order-contact-info-mfe scope-1892">
      <HeadingBanner
        variant="boxed"
      >
        <h2 data-prop="">{orderContactData?.headingLabel?.value}</h2>
      </HeadingBanner>
      <FieldGroup
        legend="Contact Information"
      >
        <InputField
          id="email"
          type="email"
          label={orderContactData?.emailLabel?.value}
          name={`addresses.${orderContactData.addressType}.email`}
          value={emailValue}
          onChange={(e) => setEmailValue(e.target.value)}
          onBlur={validateEmail}
          isInvalid={emailInvalid}
          isDisabled={userData.isLoggedIn && emailValue?.length && !emailInvalid}
          required
          autoComplete=""
        >
          {emailInvalid && (
          <ErrorMessage id="email-invalid-error">
              {orderContactData?.invalidEmailLabel?.value}
          </ErrorMessage>
          )}
        </InputField>

        <InputField
          id="phoneNumber"
          type="tel"
          label={orderContactData?.phoneNumberLabel?.value}
          name={`addresses.${orderContactData.addressType}.phone`}
          value={phoneValue}
          onChange={(e) => setPhoneValue(e.target.value)}
          onBlur={validatePhone}
          isInvalid={phoneInvalid}
          required
          autoComplete="tel"
        >
          {phoneInvalid && (
            <ErrorMessage id="phone-invalid-error">
              {orderContactData?.invalidPhoneLabel?.value}
            </ErrorMessage>
          )}
        </InputField>
      </FieldGroup>
      {orderContactData.addressType !== 'popins' && showSmsOptIn && !hasOnlyEGiftCard && isSmsOptInEnabled && (
      <div className="sms-section">
        <Checkbox
          description={orderContactData.smsOptInLabel?.value}
          id="smsOptIn-id"
          name="smsOptIn"
          value={`${smsOptIn}`}
          onChange={(e) => setSmsOptIn(e.currentTarget.checked)}
          isChecked={smsOptIn}
        />
        <LegalText>
          {orderContactData.smsOptInDetailedLabel?.value?.split('{0}')[0]}
          <Link
            href={orderContactData.privacyUrl}
            target="_blank"
          >
            {orderContactData.smsOptInPrivacyNotice.value}
          </Link>

          &nbsp;
          {orderContactData.smsOptInAnd.value}
          &nbsp;
          <Link
            href={orderContactData.textTermUrl}
            target="_blank"
          >
            {orderContactData.smsOptInTextTerm.value}
          </Link>
          &nbsp;
          {orderContactData.smsOptInDetailedLabel?.value?.split('{1}')[1]}
        </LegalText>
      </div>
      )}
    </div>
  );
}

OrderContactInfo.propTypes = {
  orderContactData: PropTypes.shape({
    hideContactInfo: PropTypes.bool,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    smsOptIn: PropTypes.bool,
    privacyUrl: PropTypes.string,
    textTermUrl: PropTypes.string,
    smsOptInLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    smsOptInDetailedLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    smsOptInPrivacyNotice: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    smsOptInTextTerm: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    smsOptInAnd: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    headingLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    emailLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    phoneNumberLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    invalidEmailLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    invalidPhoneLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    addressType: PropTypes.string,
  }),
  userData: PropTypes.shape({
    isLoggedIn: PropTypes.bool,
  }),
  hasOnlyEGiftCard: PropTypes.bool,
  isSmsOptInEnabled: PropTypes.bool,
};

OrderContactInfo.defaultProps = {
  orderContactData: {
    hideContactInfo: false,
    email: '',
    phoneNumber: '',
    smsOptIn: false,
    privacyUrl: '',
    textTermUrl: '',
    smsOptInLabel: {
      key: '',
      value: 'Sign up to receive text notifications about your shipment',
    },
    smsOptInDetailedLabel: {
      key: '',
      value: 'Once your order has shipped, you will receive automated text messages to the phone number you provided. Message & data rates may apply. Message frequency varies. Reply STOP to opt out of these text notifications. View our {0} and {1} that apply.',
    },
    smsOptInPrivacyNotice: {
      key: 'SMSOPTIN_PRIVACY_NOTICE',
      value: 'Privacy Notice',
    },
    smsOptInTextTerm: {
      key: 'SMSOPTIN_TEXT_TERMS',
      value: 'Text Terms',
    },
    smsOptInAnd: {
      key: 'SMSOPTIN_AND',
      value: 'and',
    },
    emailLabel: {
      key: '',
      value: 'Email Address',
    },
    phoneNumberLabel: {
      key: '',
      value: 'Phone Number',
    },
    headingLabel: {
      key: '',
      value: 'Order Contact',
    },
    invalidEmailLabel: {
      key: '',
      value: 'Please enter a valid email address.',
    },
    invalidPhoneLabel: {
      key: '',
      value: 'Please enter a valid phone number.',
    },
    addressType: 'homeDelivery',
  },
  userData: {
    isLoggedIn: false,
  },
  hasOnlyEGiftCard: false,
  isSmsOptInEnabled: false,
};
