import React, { useContext, useEffect, useState } from 'react';
import { ErrorMessage, Icon } from 'anf-core-react';
import Tmnt from '../../Tmnt/Tmnt';
import CheckoutPageContext from '../../../context/CheckoutPageContext';
import { FRANCHISE_COUNTRY_REPUDIATE } from '../../../tools/constants';
import { useIsM } from '../../../hooks/useBreakPoint';
import $window from '../../../tools/window';

export default function FranchiseCountryRepudiationError() {
  const isMobileScreen = useIsM();
  const { checkoutPageState } = useContext(CheckoutPageContext);
  const [franchiseCountryRepudiationData, setFranchiseCountryRepudiationData] = useState({
    isFranchiseCountryRepudiation: false,
    redirectURL: '',
  });

  const {
    franchiseCountryRepudiationError = { key: '', value: '' },
    franchiseCountryRepudiationErrorMobile = { key: '', value: '' },
    franchiseCountryPartnerSiteRedirectNotice = { key: '', value: '' },
    itemsNotSavedOrTransferredAndPromotionsMayVary = { key: '', value: '' },
  } = checkoutPageState.textFor || {};

  const {
    code: errorCode = '',
    params: [redirectURLFromResonse] = [''],
  } = checkoutPageState?.orderSubmit?.statusMessages?.[0] || {};

  // This handles the franchiseCountryRepudiation error from the cart post
  // when the order is submitted through payment methods processed by the checkout MFE.
  useEffect(() => {
    const isFranchiseCountryRepudiation = errorCode === FRANCHISE_COUNTRY_REPUDIATE;
    if (isFranchiseCountryRepudiation) {
      setFranchiseCountryRepudiationData({
        isFranchiseCountryRepudiation,
        redirectURL: redirectURLFromResonse,
      });
      $window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [errorCode, redirectURLFromResonse]);

  // This handles the franchiseCountryRepudiation error from the cart post
  // when the order is submitted through payment methods processed by CRS.
  useEffect(() => {
    const crsFranchiseCountryRepudiationErrorHandler = ({
      detail: {
        redirectURL,
      },
    }) => {
      setFranchiseCountryRepudiationData({
        isFranchiseCountryRepudiation: true,
        redirectURL,
      });
      $window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    $window.addEventListener('crs:franchiseCountryRepudiation:error', crsFranchiseCountryRepudiationErrorHandler);

    return () => {
      $window.removeEventListener(
        'crs:franchiseCountryRepudiation:error',
        crsFranchiseCountryRepudiationErrorHandler,
      );
    };
  }, []);

  const {
    isFranchiseCountryRepudiation,
    redirectURL,
  } = franchiseCountryRepudiationData;

  if (!isFranchiseCountryRepudiation) {
    return null;
  }

  franchiseCountryRepudiationError.value = franchiseCountryRepudiationError.value.replace('{0}', redirectURL);

  return (
    <div className="franchise-country-repudiation-container">
      <ErrorMessage id="summary-error-message" className="summary-error-message">
        { isMobileScreen
          ? <Tmnt tmnt={franchiseCountryRepudiationErrorMobile} />
          : <Tmnt tmnt={franchiseCountryRepudiationError} isHtml />}
      </ErrorMessage>
      {isMobileScreen ? null
        : (
          <>
            <div className="franchise-country-redirect-notice">
              <Tmnt tmnt={franchiseCountryPartnerSiteRedirectNotice} />
            </div>
            <div className="franchise-country-items-not-saved-notice">
              <Icon
                icon="exclamation"
                labelText="exclamation"
                size="xs"
              />
              <Tmnt tmnt={itemsNotSavedOrTransferredAndPromotionsMayVary} attributes={{ className: 'message' }} />
            </div>
          </>
        )}
    </div>
  );
}
