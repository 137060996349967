import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Disclosure } from 'anf-core-react';

export default function SelectedStore(props) {
  const {
    storeName,
    storeBrand,
    address,
    providerType,
    tmntData,
    displayOperatingHours,
    storeOperatingHours,
    estimatedPickupDate,
    staticMapImageUrl,
    mapAndHoursUrl,
    isCheckoutPage,
    onChangeStore,
    allowChangeStore,
    isStore,
    onClickInfo,
  } = props;

  const [isDisclosureExpanded, setIsDisclosureExpanded] = useState(false);

  const { disclosureLabel, storeOpenHours } = useMemo(() => {
    if (!isStore) {
      return {
        disclosureLabel: tmntData?.storeHoursTmnt?.value,
        storeOpenHours: storeOperatingHours,
      };
    }

    const currentDay = new Date().getDay();
    const todayHour = storeOperatingHours?.[currentDay];
    if (!todayHour) return '';

    const storeHours = [
      ...storeOperatingHours.slice(currentDay + 1),
      ...storeOperatingHours.slice(0, currentDay),
    ];
    return {
      disclosureLabel: `${tmntData?.today?.value} ${todayHour?.openHours} - ${todayHour?.closeHours}`,
      storeOpenHours: storeHours,
    };
  }, [isStore, tmntData, storeOperatingHours]);

  return (
    <section data-testid="selected-store" className="selected-store-wrapper">
      <div className="store-card">
        <div
          className="store-card-left-wrapper"
          style={staticMapImageUrl ? {
            backgroundImage: `url(${staticMapImageUrl})`,
          } : {}}
          role="img"
          aria-label={`Map of ${storeName} at ${storeName}`}
          data-testid="google-map-marker-image"
        >
          {!staticMapImageUrl && (
            <div className="store-information">
              {isStore ? (
                <div className="logo" data-testid="store-logo">
                  <p className="logo-icon" data-brand={storeBrand} data-type="horizontal" aria-hidden="true" />
                  <span className="screen-reader-text">abercrombie kids</span>
                </div>
              ) : <h4>{storeName}</h4>}

              <a href={mapAndHoursUrl} target="_blank" referrerPolicy="no-referrer" rel="noreferrer">
                {tmntData?.mapAndHours?.value}
              </a>
            </div>
          )}
        </div>

        <div className="store-card-right-wrapper">
          <div className="store-card-content">
            <div className="store-icon-block-wrapper">
              <Icon
                icon="location-outline"
                size="s"
              />
              <div className="store-card-icon-block-content">
                <div className="address-information">
                  <div className="address-header">
                    <div>
                      {isStore && (
                        <div className="logo" data-testid="store-logo">
                          <p className="logo-icon" data-brand={storeBrand} data-type="horizontal" aria-hidden="true" />
                          <span className="screen-reader-text">abercrombie kids</span>
                        </div>
                      )}
                      <h4>{storeName}</h4>
                    </div>
                    {allowChangeStore && <button data-testid="store-change-btn" type="button" className="btn-change" onClick={onChangeStore}>Change</button>}
                  </div>

                  {!isStore && <p>{address?.street}</p>}
                  {!isStore && <p>{`${address?.city}, ${address?.country} ${address?.postalCode}`}</p>}
                </div>
                {(displayOperatingHours && storeOperatingHours?.length > 0) && (
                  <Disclosure
                    id="disclosure-store-hours"
                    label={disclosureLabel}
                    onClick={() => { setIsDisclosureExpanded(!isDisclosureExpanded); }}
                    isExpanded={isDisclosureExpanded}
                  >
                    <table className="store-hours" role="presentation">
                      <tbody>
                        {storeOpenHours?.map((operatingHour) => (
                          <tr key={operatingHour?.dayOfWeek}>
                            <td>
                              {
                                tmntData?.[operatingHour.dayOfWeek.toLowerCase()]?.value
                                || operatingHour.dayOfWeek
                              }
                            </td>
                            <td>{`${operatingHour?.openHours} - ${operatingHour?.closeHours}`}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Disclosure>
                )}
              </div>
            </div>
          </div>
          <div className="store-card-content">
            <div className="omni-info">
              <div className="omni-info-block">
                <div className="omni-info-text-container">
                  <div className="icon-block-wrapper">
                    <Icon
                      icon="checkmark"
                      size="s"
                    />
                    <div className="omni-info-text-wrapper">
                      <div className="omni-info-text-icon-wrapper">
                        <p>{tmntData?.yourPickupLocationTmnt?.value}</p>
                        {isStore
                          && <button onClick={onClickInfo} className="omni-info-help-icon-button" data-icon="question-outline" data-modal="ds-modal-popins-info" aria-label={tmntData?.pickUpOrderInfoTmnt?.value} type="button" />}
                      </div>
                      <p className="estimated-pickup-date">{`${tmntData?.estimatedPickupTmnt?.value} ${estimatedPickupDate}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isCheckoutPage && (
              <div data-testid="hidden-input-fields">
                <input type="hidden" name="addresses.pudos.orgName" value={storeName} />
                <input type="hidden" name="addresses.pudos.isDefaultShipping" value="" />
                <input type="hidden" name="addresses.pudos.isDefaultBilling" value="" />
                <input type="hidden" name="addresses.pudos.address1" value={address?.street} />
                <input type="hidden" name="addresses.pudos.city" value={address?.city} />
                <input type="hidden" name="addresses.pudos.state" value={address?.state} />
                <input type="hidden" name="addresses.pudos.postalCode" value={address?.postalCode} />
                <input type="hidden" name="addresses.pudos.storeId" value="" />
                <input type="hidden" name="addresses.pudos.country" value={address?.country} />
                <input type="hidden" name="addresses.pudos.collectionPointProvider" value={providerType} />
              </div>
            )}

          </div>
        </div>
      </div>
    </section>
  );
}

SelectedStore.defaultProps = {
  storeName: '',
  storeBrand: '',
  address: {
    street: '',
    city: '',
    country: '',
    postalCode: '',
    state: '',
  },
  providerType: '',
  tmntData: {},
  displayOperatingHours: true,
  storeOperatingHours: [{
    dayOfWeek: '',
    openHours: '',
    closeHours: '',
  }],
  estimatedPickupDate: '',
  staticMapImageUrl: '',
  mapAndHoursUrl: '',
  isCheckoutPage: true,
  onClickInfo: () => { },
  onChangeStore: () => { },
  allowChangeStore: false,
  isStore: false,
};

SelectedStore.propTypes = {
  storeName: PropTypes.string,
  storeBrand: PropTypes.string,
  address: PropTypes.shape({
    street: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
  }),
  providerType: PropTypes.string,
  tmntData: PropTypes.instanceOf(Object),
  displayOperatingHours: PropTypes.bool,
  storeOperatingHours: PropTypes.arrayOf(PropTypes.shape({
    dayOfWeek: PropTypes.string,
    openHours: PropTypes.string,
    closeHours: PropTypes.string,
  })),
  estimatedPickupDate: PropTypes.string,
  staticMapImageUrl: PropTypes.string,
  mapAndHoursUrl: PropTypes.string,
  isCheckoutPage: PropTypes.bool,
  onChangeStore: PropTypes.func,
  onClickInfo: PropTypes.func,
  allowChangeStore: PropTypes.bool,
  isStore: PropTypes.bool,
};
